import React from "react"
import styled from "styled-components"
import { Link, navigate } from "gatsby"
import { useState } from "react"
import api from "../components/api"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3"
import { useForm } from "react-hook-form"

const ResetPasswordStyle = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 85vh;

  .card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 520px;
    height: 376px;
    background: #ffffff;
    box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    @media (max-width: 560px) {
      width: 90%;
    }

    .form-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 268px;
      width: 384px;

      @media (max-width: 560px) {
        width: max-content;
      }

      .form-name {
        align-self: center;
        width: max-content;
        height: 40px;
        font-family: Work Sans;
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 40px;
        text-align: center;
        color: #111111;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 32px 0px;

        @media (max-width: 560px) {
          font-size: 26px;
          line-height: 30px;
          width: 100%;
        }
      }

      label {
        align-self: flex-start;
        font-family: Work Sans;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #111111;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 8px 0px;
        @media (max-width: 560px) {
          align-self: center;
          width: 209px;
        }
      }

      input {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        font-family: Roboto;
        padding: 16px;
        width: 384px;
        height: 51px;
        border: 1px solid #d8d8d8;
        box-sizing: border-box;
        border-radius: 5px;
        flex: none;
        align-self: stretch;
        flex-grow: 0;
        margin: 8px 0px;

        @media (max-width: 560px) {
          align-self: center;
          width: 209px;
        }
      }
      .alert-fail {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 384px;
        height: 40px;
        padding: 8px 16px;
        background: #ffe4e6;
        border-radius: 4px;
        flex-grow: 1;
        margin: 0px 0px;
        margin-bottom: 0px;
        cursor: pointer;
        &:hover {
          background: rgb(50, 205, 50);
        }

        @media (max-width: 560px) {
          width: max-content;
          padding: 4px 8px;
        }
        .alert-img {
          margin-right: 5px !important;
        }
      }
      button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px;
        width: 384px;
        height: 50px;
        background: #00ca42;
        color: white;
        border-radius: 60px;
        border: none;
        flex: none;
        align-self: stretch;
        flex-grow: 0;
        margin: ${({ fail }) => (fail ? true : "12px 0px")};
        cursor: pointer;
        &:hover {
          background: rgb(50, 205, 50);
        }
        @media (max-width: 560px) {
          width: 209px;
          align-self: center;
        }
      }
      .back {
        width: max-content;
        height: 21px;
        font-family: Work Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        color: #007de6;
        flex: none;
        flex-grow: 0;
        margin: 24px 0px;

        @media (max-width: 560px) {
          width: 80%;
          align-self: center;
        }
      }
    }
    .success-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 520px;
      height: 376px;
      background: #ffffff;
      box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.1);
      border-radius: 10px;

      @media (max-width: 560px) {
        width: 90%;
      }

      .success-name {
        width: 305px;
        height: 40px;
        font-family: Work Sans;
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 40px;
        text-align: center;
        color: #111111;
        flex: none;
        flex-grow: 0;
        margin: 24px 0px;
      }

      .success-text {
        width: 305px;
        height: 54px;
        font-family: Work Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: #222222;
        flex: none;
        align-self: center;
        flex-grow: 0;
        margin: 24px 0px;
      }
    }
  }
`

function ResetPassword() {
  //state that manages if the password reset request is successfully sent
  const [success, setSuccess] = useState(false)

  //is button disabled state
  const [isDisabled, setIsDisabled] = useState(false)

  //checking if window exsist - because of compiler
  if (typeof window !== "undefined") {
    if (JSON.parse(localStorage.getItem("isLogged")) === true) {
      navigate("/app/dashboard")
    }
  }

  const [verify, setVerify] = useState(false)

  function verification() {
    setVerify(true)
  }

  function onSubmit(data) {
    api
      .post("/api/v1/pdfpro/password-reset-init/", {
        email: data.Email,
        company_code: "pdfpro",
      })
      .then(res => {
        console.log(res)
        setSuccess(true)
        setIsDisabled(false)
      })
      .catch(err => {
        console.log(err)
        setError("!EmailExists", {
          type: "!EmailExists",
          message: "Email doesn't exist",
        })
        setIsDisabled(false)
      })
  }

  const {
    register,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm()

  return (
    <>
      <Seo title="Reset Password" />
      <ResetPasswordStyle>
        <div className="card-container">
          {!success && (
            <GoogleReCaptchaProvider
              reCaptchaKey="6LehzegcAAAAANgypHB5fY0BC-L3H9s5NuBUA7Xt"
              render="explicit"
            >
              <form
                className="form-container"
                onSubmit={handleSubmit(onSubmit)}
              >
                <h1 className="form-name">Forgot password </h1>
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter email"
                  {...register("Email", {
                    required: true,
                    onChange: () => {
                      clearErrors("!EmailExists")
                    },
                  })}
                />
                {/* It shows all error messages */}
                {Object.values(errors).map(prop => {
                  return (
                    <div className="alert-fail">
                      <StaticImage
                        src="../images/alert-fail.png"
                        width={17}
                        quality={95}
                        formats={["AUTO", "WEBP", "AVIF", "png"]}
                        loading="eager"
                        placeholder="blurred"
                        alt="fail-alert"
                        className="alert-img"
                      />
                      {prop.message}
                    </div>
                  )
                })}
                <button disabled={isDisabled}>Reset Password</button>
                <GoogleReCaptcha
                  onVerify={() => {
                    verification()
                  }}
                />
                <Link className="back" to="/login">
                  Back to login
                </Link>
              </form>
            </GoogleReCaptchaProvider>
          )}
          {success && (
            <div className="success-box">
              <StaticImage
                src="../images/success-img.png"
                width={33}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF", "png"]}
                loading="eager"
                placeholder="blurred"
                alt="success-img"
              />
              <h2 className="success-name">Check your inbox</h2>
              <span className="success-text">
                You will recieve a link which will <br></br> allow you to reset
                your password
              </span>
            </div>
          )}
        </div>
      </ResetPasswordStyle>
    </>
  )
}

export default ResetPassword
